import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/layout'
import PuxMetaTags from '../components/shared/PuxMetaTags'
import WidgetBuilder from '../components/builders/widget-builder'
import BreadCrumbs from '../components/BreadCrumbs'

const JobOfferDetail = ({ data, pageContext }: any) => {
  const page = data.orchard.puxDesignJobOffer[0]

  return (
    <Layout localizedPath={page.localization?.localizations}>
      <PuxMetaTags {...pageContext.metaTagsData} />
      <BreadCrumbs items={pageContext.breadcrumbs} center={false} />
      <section className="">
        <div className="pux-container pb-70 pt-50 center-content">
          <div className="narrow">
            <h1 className="mb-30 mt-0">{page.displayText}</h1>
            <div
              className="perex"
              dangerouslySetInnerHTML={{ __html: page.jobOfferPerex?.replace(/font-size: 1rem;/g, ``) }}
            ></div>
          </div>
        </div>
      </section>

      <WidgetBuilder widgetBuilderData={pageContext.widgets} />
    </Layout>
  )
}

export default JobOfferDetail

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      puxDesignJobOffer(first: 1, where: { path: $pagePath }) {
        path
        jobOfferPerex
        displayText
        localization {
          localizations {
            ... on Orchard_PuxDesignJobOffer {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`
